import React, { useState, useEffect } from "react";
import "../css/videos.css";
import Footer from "./Footer";
import Header2 from "./Header2";
import logoHeader from "../imagesTelegu/telugu.png";
import img1 from "../imagesTelegu/1.jpeg";
import img2 from "../imagesTelegu/2.jpeg";
import img3 from "../imagesTelegu/3.jpeg";
import img4 from "../imagesTelegu/4.jpeg";
import img5 from "../imagesTelegu/5.jpeg";
import img6 from "../imagesTelegu/6.jpeg";
import img7 from "../imagesTelegu/7.jpeg";
import img8 from "../imagesTelegu/8.jpeg";
import img9 from "../imagesTelegu/9.jpeg";
import img11 from "../imagesTelegu/11.jpeg";
import img12 from "../imagesTelegu/12.jpeg";
import img13 from "../imagesTelegu/13.jpeg";
import img14 from "../imagesTelegu/14.jpeg";
import img15 from "../imagesTelegu/15.jpeg";
import img16 from "../imagesTelegu/16.jpeg";
import img17 from "../imagesTelegu/17.jpeg";
import img18 from "../imagesTelegu/18.jpeg";
import img19 from "../imagesTelegu/19.jpeg";
import img20 from "../imagesTelegu/20.jpeg";
import emoji1 from "../emojis/a.png";
import emoji2 from "../emojis/h.png";
import emoji3 from "../emojis/c.png";
import emoji4 from "../emojis/f.png";
import emoji5 from "../emojis/i.png";
import emoji6 from "../emojis/j.png";

function Guarantee({ frontImageSrc, backImageSrc, onHover }) {
  return (
    <div className="guarantee-card" onMouseOver={onHover}>
      <div className="guarantee-card-inner">
        <div className="guarantee-card-front">
          <img src={frontImageSrc} alt="Front" className="guarantee-image" />
        </div>
        <div className="guarantee-card-back">
          <img src={backImageSrc} alt="Back" className="guarantee-image" />
        </div>
      </div>
    </div>
  );
}

function GuaranteesTelegu() {
  const guarantees = [
    { frontImageSrc: img1, backImageSrc: img11 },
    { frontImageSrc: img2, backImageSrc: img12 },
    { frontImageSrc: img3, backImageSrc: img13 },
    { frontImageSrc: img4, backImageSrc: img14 },
    { frontImageSrc: img5, backImageSrc: img15 },
    { frontImageSrc: img6, backImageSrc: img16 },
    { frontImageSrc: img7, backImageSrc: img17 },
    { frontImageSrc: img8, backImageSrc: img18 },
    { frontImageSrc: img9, backImageSrc: img19 },
  ];

  const [showPopup, setShowPopup] = useState(false);
  const [popupCount, setPopupCount] = useState(0);
  const [showEmojiContainer1, setShowEmojiContainer1] = useState(false);
  const [showEmojiContainer2, setShowEmojiContainer2] = useState(false);
  const [showEmojiContainer3, setShowEmojiContainer3] = useState(false);
  const [showEmojiContainer4, setShowEmojiContainer4] = useState(false);
  const [showEmojiContainer5, setShowEmojiContainer5] = useState(false);
  const [showEmojiContainer6, setShowEmojiContainer6] = useState(false);
  const [showEmojiContainer7, setShowEmojiContainer7] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    if (hovered) {
      setShowPopup(true);
      setTimeout(() => {
        setShowEmojiContainer1(true);
      }, 500); // Add a 1-second delay
      setTimeout(() => {
        setShowEmojiContainer2(true);
      }, 1000); // Add a 1-second delay
      setTimeout(() => {
        setShowEmojiContainer3(true);
      }, 2000); // Add a 2-second delay
      setTimeout(() => {
        setShowEmojiContainer4(true);
      }, 3000); // Add a 3-second delay
      setTimeout(() => {
        setShowEmojiContainer5(true);
      }, 4000); // Add a 3-second delay
      setTimeout(() => {
        setShowEmojiContainer6(true);
      }, 5000); // Add a 3-second delay
      setTimeout(() => {
        setShowEmojiContainer7(true);
      }, 6000); // Add a 3-second delay
      setTimeout(() => {
        setShowEmojiContainer1(false);
        setShowEmojiContainer2(false);
        setShowEmojiContainer3(false);
        setShowEmojiContainer4(false);
        setShowEmojiContainer5(false);
        setShowEmojiContainer6(false);
        setShowEmojiContainer7(false);
      }, 10000); // Stop emojis after 10 seconds
    }
  }, [hovered]);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showEmojiContainer1 && (
        <>
          <div className="emoji-container">
            <img src={emoji1} alt="Emoji 1" className="falling-emoji2" />
          </div>
        </>
      )}
      {showEmojiContainer2 && (
        <>
          <div className="emoji-container2">
            <img src={emoji2} alt="Emoji 1" className="falling-emoji" />
          </div>
        </>
      )}
      {showEmojiContainer3 && (
        <>
          <div className="emoji-container3">
            <img src={emoji1} alt="Emoji 1" className="falling-emoji2" />
          </div>
        </>
      )}
      {showEmojiContainer4 && (
        <>
          <div className="emoji-container4">
            <img src={emoji3} alt="Emoji 1" className="falling-emoji2" />
          </div>
        </>
      )}
      {showEmojiContainer5 && (
        <>
          <div className="emoji-container5">
            <img src={emoji5} alt="Emoji 1" className="falling-emoji" />
          </div>
        </>
      )}
      {showEmojiContainer6 && (
        <>
          <div className="emoji-container6">
            <img src={emoji4} alt="Emoji 1" className="falling-emoji" />
          </div>
        </>
      )}
      {showEmojiContainer7 && (
        <>
          <div className="emoji-container7">
            <img src={emoji6} alt="Emoji 1" className="falling-emoji" />
          </div>
        </>
      )}

      <Header2 />
      <div className="main-div">
        <div>
          <img
            src={logoHeader}
            height="150"
            width="100%"
            alt="Header"
            style={{
              marginTop: "35px",
            }}
            className="head-image"
          />
        </div>
        <p className="sub-text">
          ప్రియమైన ఆంధ్రప్రదేశ్‌ ప్రజలారా.. ఈ ఐదేళ్లూ కొన్ని కారణాల వల్ల
          నవరత్నాల పథకాలను అస్సలు సక్రమంగా అమలు చేయలేకపోయాం. అందుకే.. మాకు
          దయచేసి మరొక్క ఛాన్స్‌ ఇవ్వండి. ఈ సారి కచ్చితంగా అమలు చేయడానికి మళ్లీ
          ప్రయత్నిస్తాం. ఈసారి ఏ పథకంలో ఎటువంటి మార్పులు చేయబోతున్నామో
          తెలుసుకోండి..
        </p>
        <div className="guarantees">
          {guarantees.map((guarantee, index) => (
            <Guarantee
              key={index}
              frontImageSrc={guarantee.frontImageSrc}
              backImageSrc={guarantee.backImageSrc}
              onHover={() => {
                if (index === 2) {
                  setHovered(true);
                }
              }}
            />
          ))}
        </div>
      </div>
      <Footer />
      {showPopup && (
        <>
          <div className="popup">
            <button className="exit-button" onClick={closePopup}>
              X
            </button>
            <img src={img20} alt="Popup" className="popup-image" />
          </div>
        </>
      )}
    </>
  );
}

export default GuaranteesTelegu;
