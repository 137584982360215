import React from 'react';

const Footer = () => {
  const footerStyle = {
    backgroundColor: 'black',
    color: '#FFFFFF',
    textAlign: 'center',
    padding: '10px 0',
    width: '100%',
  };

  return (
    <footer style={footerStyle}>
      <p>Copyright 2024 © ycpnavaratnas2024.com</p>
    </footer>
  );
};

export default Footer;
