import React from "react";
import "../css/header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import img from "../images/logo.png";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const footerStyle = {
    backgroundColor: "black",
    color: "#FFFFFF",
    textAlign: "center",
    padding: "10px 0",
    width: "100%",
  };

  const globeIconStyle = {
    width: "20px",
    height: "20px",
    marginRight: "5px",
  };

  const handleClick = () => {
    navigate('/');
  };

  return (
    <footer style={footerStyle}>
      <div className="logo-image">
        <img src={img} height="100" width="100" alt="Logo" />
        <div className="button-div">
          <button className="button-icon" onClick={handleClick}>
            <FontAwesomeIcon icon={faGlobe} />
            తెలుగు
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Header;
